import { http } from './config'
import authHeader from './auth-header';

export default {

    sepultado: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }

        return http.get('/sepultamento-dash/total-sepultamento/', {
            params: params,
            headers: authHeader() 
        })
    },


    sepultado_plano: (dataI, dataF, tipo, empresa) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'tipo': tipo,
            'empresa': empresa,
        }

        return http.get('/sepultamento-dash/total-sepultamento-plano/', {
            params: params,
            headers: authHeader() 
        })
    },


    total_imediato: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }

        return http.get('/sepultamento-dash/total-imediato/', {
            params: params,
            headers: authHeader() 
        })
    },



    valor_imediato: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }

        return http.get('/sepultamento-dash/valor-imediato/', {
            params: params,
            headers: authHeader() 
        })
    },



    total_preventivo: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }

        return http.get('/sepultamento-dash/total-preventivo/', {
            params: params,
            headers: authHeader() 
        })
    },

    valor_preventivo: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }

        return http.get('/sepultamento-dash/valor-preventivo/', {
            params: params,
            headers: authHeader() 
        })
    },
    sepultameto_empresa: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }

        return http.get('/sepultamento-dash/sepultamento-empresa/', {
            params: params,
            headers: authHeader() 
              
        })
    },

    sepultameto_funeraria_previda: (dataI, dataF, tipo, empresa) => {
        
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'tipo': tipo,
            'empresa': empresa,
        }

        return http.get('/sepultamento-dash/total-funeraria-previda', {
            params: params,
            headers: authHeader() 
        })
    },

    sepultameto_funeraria: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }

        return http.get('/sepultamento-dash/sepultamento-funeraria/', {
            params: params,
            headers: authHeader() 
        })
    },


    sepultamento_plano: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }
        return http.get('/sepultamento-dash/sepultamento-plano/', {
            params: params,
            headers: authHeader() 
              
        })
    },


    imediato_parcelamento: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }

        return http.get('/sepultamento-dash/imediato-parcelamento/', {
            params: params,
            headers: authHeader() 
              
        })
    },


    preventivo_parcelamento: (dataI, dataF, empresa) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': empresa,
        }

        return http.get('/sepultamento-dash/preventivo-parcelamento/', {
           params: params,
            headers: authHeader() 
              
        })
    },


    total_jazigo: (texto) => {
        return http.get('/sepultamento-dash/total-jazigo/'+texto, { headers: authHeader() })
    },

    jazigo_vendido: (texto) => {
        return http.get('/sepultamento-dash/jazigo-vendido/'+texto, { headers: authHeader() })
    },

    total_gavetas: (texto) => {
        return http.get('/sepultamento-dash/total-gavetas/'+texto, { headers: authHeader() })
    },


    total_columbario_disponivel: (texto) => {
        return http.get('/sepultamento-dash/total-columbario-disponivel/'+texto, { headers: authHeader() })
    },


    total_gavetas_disponivel: (texto) => {
        return http.get('/sepultamento-dash/total-gavetas-disponivel/'+texto, { headers: authHeader() })
    },

    total_exumados: (texto) => {
        return http.get('/sepultamento-dash/total-exumados/'+texto, { headers: authHeader() })
    },

    total_cremados: (texto) => {
        return http.get('/sepultamento-dash/total-cremados/'+texto, { headers: authHeader() })
    },


   

}
